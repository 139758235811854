import React from "react";
import "./About.css";

function About() {
    return (
        <>
            <div className="Under-Construction">
                <img
                    className="Under-Construction-image"
                    src="https://i.imgur.com/RtWx3ca.png"
                    alt="Girl in a jacket"
                />

                <h1> Page is Under Construction! </h1>
            </div>
        </>
    );
}

export default About;
